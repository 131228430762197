<template>
  <div class="search_college">
    <div class="tabs">
      <!--            专业类别-->
      <el-row class="search_line">
        <el-col :span="3"><span class="title">专业类别></span></el-col>
        <el-col :span="21">
          <span :class="{ item: true, act: check.parent_id.length === 0 }" @click="choose('parent_id', -1, 1)">全部</span>
          <span v-for="(item, i) in SubjectCodeList" :key="i" :class="{
            item: true,
            act: check.parent_id.indexOf(item.subject_code) > -1,
          }" @click="choose('parent_id', item.subject_code, 1)">{{ item.subject_code }}-{{ item.name }}
          </span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import datasss from "@/assets/data/conditional-search.js";
export default {
  name: "ConditionalSearch",
  props: ["condition"],
  data() {
    return {
      check: {
        parent_id: []
      },

      SubjectCodeList: [
        {
          subject_code: "00",
          name: "试验班类",
        },
        {
          subject_code: "01",
          name: "哲学",
        },
        {
          subject_code: "02",
          name: "经济学",
        },
        {
          subject_code: "03",
          name: "法学",
        },
        {
          subject_code: "04",
          name: "教育学",
        },
        {
          subject_code: "05",
          name: "文学",
        },
        {
          subject_code: "06",
          name: "历史学",
        },
        {
          subject_code: "07",
          name: "理学",
        },
        {
          subject_code: "08",
          name: "工学",
        },
        {
          subject_code: "09",
          name: "农学",
        },
        {
          subject_code: "10",
          name: "医学",
        },
        {
          subject_code: "11",
          name: "管理学",
        },
        {
          subject_code: "12",
          name: "艺术学",
        },
      ]

    };
  },
  created() {
    this.cityList = datasss.city;
  },
  methods: {
    choose(type, val, isArr) {
      if (isArr === 1) {
        if (val === -1) {
          this.check[type] = [];
        } else {
          const index = this.check[type].indexOf(val);
          if (index > -1) {
            this.check[type].splice(index, 1);
          } else {
            this.check[type].push(val);
          }
        }
      } else if (isArr === 0) {
        this.check[type] = val;
      }
      this.$emit("onSearch", this.check);
    },
    doSearch() {
      this.$emit("onSearch", this.check);
    },
  },
};
</script>

<style lang="less" scoped>
.search_line {
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 0;
}

.search_line:last-child {
  border: 0;
}

.search_college {
  .tabs {
    color: #666666;
    letter-spacing: 2px;
    border: 1px solid #dfdfdf;
    padding: 15px;

    .title {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
      text-align: center;
    }

    span {
      display: inline-block;
      padding: 0 12px;
      line-height: 27px;
      margin-bottom: 3px;

      &:hover {
        background: #e08245;
        color: white;
      }
    }
  }

  .act {
    background-color: #e08245;
    color: #ffffff;
  }
}
</style>