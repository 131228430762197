<template>
  <div>
    <ConditionalSearch @onSearch="onSearch"> </ConditionalSearch>

    <div class="tableTitle">
      <el-row>
        <el-col :span="8">专业名称</el-col>
        <el-col :span="4">优势组合</el-col>
        <el-col :span="4">查看院校</el-col>
        <el-col :span="4">匹配率</el-col>
        <el-col :span="4">操作</el-col>
      </el-row>
    </div>

    <div v-for="(item, i) in specialtyListData" :key="i" class="tableTitle">
      <el-row type="flex" align="middle">
        <el-col :span="8" style="text-align: left;line-height: 160%;">
          <h3>{{ item.specialty_name }}</h3>
          <p> 代码：{{ item.code_id }}</p>
          <p> 开设院校：<span class="btn">{{ item.child_num }} </span>所 开设专业 <span span class="btn">{{ item.child_specialty_num
          }} </span> </p>
          <span v-for="(value, key) in item.school_tip_id" :key="key">{{ value }} <span
              v-if="key != item.school_tip_id.length - 1">、</span> </span>
        </el-col>
        <el-col :span="4">
          <div v-for="(value, key) in  item.combine" :key="key">
            <p v-if="key < 3" style="margin-top: 5px;"> {{ key + 1 + ',' }} {{ value.combine }}</p>
          </div>
        </el-col>
        <el-col :span="4"> <span class="btn" @click="checkSpecalty(item)">匹配院校></span> </el-col>
        <el-col :span="4"> <span class="btn" @click="checkCombine(item)">匹配率></span> </el-col>
        <el-col :span="4">
          <span v-if="categroyCode.indexOf(item.code_id) > -1" @click="add(item)" class="act">已选择</span>
          <span v-else @click="add(item)">选择</span>
        </el-col>
      </el-row>
    </div>
    <!-- 分页 -->
    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="page.pageSize" layout=" prev, pager, next"
            :total="page.total">
          </el-pagination>
        </div>
      </template>
    </div>

    <el-dialog :title="specialty_name" :visible.sync="dialogVisible.specialty" width="80%" destroy-on-close
      :before-close="handleClose">
      <el-table :data="specialtyList" style="width: 100%" border height="400px" class="spTable">
        <el-table-column prop="name" label="院校" width="180">
        </el-table-column>
        <el-table-column prop="specialty_name" label="专业名称">
        </el-table-column>
        <el-table-column prop="direction" label="招考方向">
        </el-table-column>
        <el-table-column prop="contain" label="包含专业">
        </el-table-column>
        <el-table-column prop="demand" label="科目要求">
        </el-table-column>
      </el-table>
      <div class="main">
        <template>
          <div style="text-align: center; margin: 20px 0 0 0 ">
            <el-pagination @current-change="handleCurrentChange1" :page-size="spPage.pageSize" layout=" prev, pager, next"
              :total="spPage.total">
            </el-pagination>
          </div>
        </template>
      </div>

    </el-dialog>

    <el-dialog :title="combineCollegeName" :visible.sync="dialogVisible.combine" width="900px" destroy-on-close
      :before-close="handleClose">

      <div class="combineClass">
        <p v-for="(item, i) in combineList" :key="i">
          <span class="span_1">{{ item.combine }}：</span>
          <span class="span_2"><el-progress :percentage="Number(item.percent)" :stroke-width="20"
              :text-inside="true"></el-progress></span>
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ConditionalSearch from './components/SpecialtySearch';

export default {
  name: '',
  props: ["categroyCode"],
  data() {
    return {
      check: {},
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      spPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      specialtyListData: [],
      dialogVisible: {
        specialty: false,
        combine: false
      },
      specialty_name: '',
      code_id: '',
      combineCollegeName: '',
      specialtyList: [],
      combineList: []
    };
  },
  components: {
    ConditionalSearch

  },
  created() {
    this.getCollege()

  },

  methods: {
    onSearch(data) {
      this.page.pageNum = 1
      this.check = data
      this.getCollege()
    },

    add(item) {
      this.$emit('chooseSpecialtyCategory', item)
    },


    getCollege() {
      let data = this.check;
      data.pageNum = this.page.pageNum
      data.pageSize = this.page.pageSize
      this.$fecth.post("xuanke/x_choose/getSpecialty", data).then((res) => {
        this.specialtyListData = res.lists
        this.page.total = res.total;
      });
    },

    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getCollege()
    },
    handleCurrentChange1(val) {
      this.spPage.pageNum = val;
      this.getSpecialtyList()
    },



    checkSpecalty(item) {
      this.specialty_name = item.specialty_name
      this.code_id = item.code_id
      this.dialogVisible.specialty = true;
      this.getSpecialtyList()
    },

    getSpecialtyList() {
      let data = {
        code_id: this.code_id,
        pageNum: this.spPage.pageNum,
        pageSize: this.spPage.pageSize
      }
      this.$fecth.post("xuanke/x_choose/getSpecialtyList", data).then((res) => {
        this.specialtyList = res.lists
        this.spPage.total = res.total;
      });
    },

    checkCombine(item) {

      this.combineCollegeName = item.specialty_name
      this.code_id = item.code_id
      this.dialogVisible.combine = true;
      this.getCombineList()
    },
    getCombineList() {
      let data = {
        code_id: this.code_id,
      }
      this.$fecth.post("xuanke/x_choose/getSpecialtyCombineList", data).then((res) => {
        this.combineList = res
      });
    },

    handleClose() {
      this.specialtyList = []
      this.dialogVisible.specialty = false
      this.dialogVisible.combine = false
    },


  },
};
</script>

<style scoped lang='less'>
.tableTitle {
  padding: 25px 0;
  text-align: center;
  color: #656565;
  border-bottom: 1px solid #dfdfdf;
  vertical-align: middle;
}

.btn {
  color: #e15c00;
}

.spTable {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
}

.combineClass {
  padding: 0 40px 40px;
}

.span_1 {
  width: 80px;
  margin-left: 80px;
  display: inline-block;
  margin-top: 20px;
}

.span_2 {
  width: 540px;
  display: inline-block;
  margin-left: 20px;
}


.act {
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 14px;
  background: #e15c00;
  color: white;
  border: 1px solid #e15c00;
}
</style>
